<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="新建提现" width="700px" @closed="handleClosed">
    <template v-if="form">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
        <el-form-item prop="friendEntId" label="经销商">
          <!-- <quick-select v-model="form.friendEntId" filterable :label.sync="form.friendEntName" value-field="enterpriseId" url="api/distributor" auto-select-first-option placeholder="请选择经销商" @change="loadBalance" style="width: 230px;" /> -->
          <quick-select v-model="form.friendEntId" filterable :label.sync="form.friendEntName" value-field="enterpriseId" url="api/distributor" auto-select-first-option placeholder="请选择经销商" @change="loadBalance" style="width: 230px;" :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }" >
              <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
          </quick-select>               
        </el-form-item>
        <el-form-item prop="payMoney" label="提现金额">
          <div class="h c">
            <price-input v-model="form.payMoney" :min="0" :max="maxBalance / 100" :step-ratio="10000" style="width: 230px" />
            <div class="padding-0-10">最大可提现金额：{{$price(maxBalance)}}</div>
          </div>
        </el-form-item>
        <el-form-item prop="payTime" label="提现日期">
          <el-date-picker type="date" v-model="form.payTime" value-format="timestamp" :clearable="false" style="width: 230px" />
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.info" :maxlength="200" :rows="3" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button type="primary" :loading="saving" @click="doSubmit">提交</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { add, edit, audit } from "@/api/payForm";
import { getByDistributor } from "@/api/capitalPool";

export default {
  data() {
    return {
      dialog: false,
      form: null,
      saving: false,
      auditing: false,
      maxBalance: 0,
      rules: {
        friendEntId: [{ required: true, message: "请选择经销商" }],
        payTime: [{ required: true, message: "请选择提现日期" }],
        payMoney: [{ required: true, message: "请填写提现金额" }]
      },
      status: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "success",
          label: "已确认"
        },
        {
          type: "danger",
          label: "已拒绝"
        },
        {
          type: "primary",
          label: "已支付"
        }
      ]
    };
  },
  methods: {
    handleClosed() {
      this.form = null;
    },
    loadBalance() {
      if (this.form && this.form.friendEntId) {
        getByDistributor(this.form.friendEntId).then(res => {
          this.maxBalance = res.canUseBalance || 0;
        });
      }
    },
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.$confirm(
            "提交后将不能再进行修改，请认真核对所填信息。<br />确定要提交当前信息吗？",
            "操作确认",
            {
              type: "warning",
              dangerouslyUseHTMLString: true
            }
          ).then(_ => {
            this.saving = true;
            let form = JSON.parse(JSON.stringify(this.form));
            if (form.inOutType === "out")
              form.payMoney = Math.abs(form.payMoney) * -1;
            add(form)
              .then(res => {
                this.$notify({
                  title: `提交成功`,
                  type: "success",
                  duration: 2500
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally(_ => {
                this.saving = false;
              });
          });
        });
    },
    resetForm(form) {
      this.form = form || {
        friendEntId: null,
        friendEntName: "",
        inOutType: "out",
        relationFormType: 2,
        payMoney: 0,
        payType: 1,
        payTime: new Date().getTime(),
        payerAccount: "",
        payeeAccount: "",
        status: 0,
        projectId: null,
        projectName: "",
        info: ""
      };
      this.dialog = true;
    }
  }
};
</script>